import request from '@/utils/request';

//发送忘记密码验证码
export const getForgetCode = params =>
  request.post('/pipe-api/auth/user/sendMsg', params);

//验证码
export const getVerifyCode = params =>
  request.get('/pipe-api/auth/formula', { params });

//忘记密码后修改密码
export const getAuth = (code, params) =>
  request.post(`/pipe-api/auth/reset/${code}`, params);
