<template>
  <div class="register-box">
    <el-card shadow="never" class="card">
      <p class="header">{{ $t('forget_password_title') }}</p>
      <div class="formBox">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="username">
            <el-input
              :placeholder="$t('forget_form1_placeholder')"
              prefix-icon="el-icon-user"
              v-model.trim="form.username"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            />
          </el-form-item>
          <el-form-item prop="imgCode">
            <el-input
              :placeholder="$t('forget_form2_placeholder')"
              prefix-icon="el-icon-circle-check"
              v-model.trim="form.imgCode"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            >
              <img
                :src="verifyUrl"
                alt="verifyCode"
                slot="suffix"
                class="verifyImg"
                @click="this.changeVerifyCode"
              />
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              :placeholder="$t('forget_form3_placeholder')"
              v-model.trim="form.code"
            >
              <span slot="suffix" class="getCode" @click="_getCode">
                {{
                  show
                    ? $t('forget_code_font')
                    : $i18n.locale() === 'cn'
                    ? `${count}${$t('forget_last_time')}`
                    : `${$t('forget_last_time')} ${count} seconds`
                }}
              </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="passWord">
            <el-input
              :placeholder="$t('forget_form4_placeholder')"
              v-model.trim="form.passWord"
              autocomplete="off"
              type="password"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item prop="passWord2">
            <el-input
              :placeholder="$t('forget_form5_placeholder')"
              v-model.trim="form.passWord2"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
        <p @click="_confirmRegister(form1.code)">{{ $t('forget_button') }}</p>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getForgetCode, getVerifyCode, getAuth } from './api';

const TIME_COUNT = 120;
export default {
  data() {
    return {
      show: true, // 开始的时候启用(可以发验证码)
      count: '',
      timer: null,
      checked: false,
      form: {
        username: '',
        code: '',
        passWord: '',
        passWord2: '',
        imgCode: '',
      },
      form1: {
        code: '',
      },
      verifyUrl: '',
    };
  },
  computed: {
    rules() {
      // 验证二次输入密码
      const validatePwd2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('forget_rule_password3')));
        } else if (value !== this.form.passWord) {
          callback(new Error(this.$t('forget_rule_password4')));
        } else {
          callback();
        }
      };
      const passwordTest = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t('forget_rule_password1')));
        } else {
          const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error(this.$t('forget_rule_password2')));
          }
        }
      };
      return {
        username: [
          {
            required: true,
            message: this.$t('forget_form1_placeholder'),
            trigger: 'blur',
          },
        ],
        passWord: [{ validator: passwordTest, trigger: 'blur' }],
        passWord2: [
          {
            required: true,
            validator: validatePwd2,
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: this.$t('forget_form3_placeholder'),
            trigger: 'blur',
          },
        ],
        imgCode: [
          {
            required: true,
            message: this.$t('forget_form2_placeholder'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  mounted() {
    this._getVerifyCode();
  },
  methods: {
    async _getVerifyCode() {
      try {
        this.verifyUrl = await getVerifyCode();
      } catch (err) {
        console.error(err);
      }
    },
    changeVerifyCode() {
      this._getVerifyCode();
    },
    async _getCode() {
      if (!this.show || !this.form.username) {
        return;
      }
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
        try {
          const params = {
            username: this.form.username,
            code: this.form.imgCode,
          };
          await getForgetCode(params);
          this.$message.success(this.$t('forget_code_message'));
        } catch (err) {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          this.count = TIME_COUNT;
          console.error(err);
        }
      }
    },
    _confirmRegister(code) {
      // const code = this.form1.code
      // delete params.passWord2
      const params = { password: this.form.passWord };
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const result = await getAuth(this.form.code, params);
            if (!result) {
              this.$message.success(this.$t('forget_success_message'));
              await this.$router.push('/user/login');
            }
          } catch (err) {
            console.error(err);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.register-box {
  background-color: #fafafa;
  .el-card {
    border: none;
  }
  height: calc(100% - 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 72px 16px 72px;
  .card {
    width: 100%;
    height: 100%;
    .header {
      position: relative;
      height: 60px;
      line-height: 60px;
      width: 100%;
      font-size: 24px;
      color: #333;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 32px;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 8px;
        height: 32px;
        background: #2c68ff;
        top: 14px;
        left: -20px;
      }
    }
    .formBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .verifyImg {
        display: inline-block;
        width: 104px;
        height: 45px;
        position: relative;
        top: 3px;
        cursor: pointer;
        //border: 1px solid #cccccc;
        border-radius: 8px;
      }
      form {
        width: 500px;
        .getCode {
          color: #2c68ff;
          padding-right: 6px;
          position: relative;
          top: 8px;
          cursor: pointer;
          font-size: 14px;
        }
      }
      & > p:nth-child(2) {
        width: 344px;
        height: 56px;
        background: #2c68ff;
        border-radius: 4px;
        text-align: center;
        line-height: 56px;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
        margin-bottom: 32px;
      }
      & > p:nth-child(3) {
        font-size: 16px;
        & > span:first-child {
          color: #ccc;
        }
        & > span:nth-child(2) {
          color: #2c68ff;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep .el-dialog__footer {
    text-align: center;
  }
  .dia-header {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    color: #2c68ff;
    border-left: 8px solid #2c68ff;
    padding-left: 16px;
  }
  .txt {
    line-height: 36px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .protocol {
    display: flex;
    flex-direction: column;
    span {
      cursor: pointer;
      line-height: 42px;
      color: #2c68ff;
      font-size: 14px;
    }
  }
}

::v-deep .el-input__inner {
  height: 56px;
}
::v-deep .el-dialog {
  box-sizing: border-box;
  padding: 0 48px 48px 48px;
}
</style>
